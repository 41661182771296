
<template>
  <div class="flex min-h-screen font-sans">
    <div class="flex flex-col items-center justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:w-[800px] lg:px-20 xl:px-24 ">
      <div class="w-full lg:w-[475px]">
        <div class="w-24 ">
          <router-link
            to="/"
            class="w-24 h-24"
          >
            <img
              class="w-24 h-24"
              src="@/assets/logo.svg"
              alt="Logo"
            >
          </router-link>
        </div>

        <h3 class="mt-6 text-lg font-bold leading-6 text-white uppercase">
          FORGOT PASSWORD
        </h3>

        <h2 class="text-3xl font-extrabold text-primary">
          Can I get a res plz?
        </h2>

        <div class="mt-8">
          <div class="mt-6">
            <form
              action="#"
              method="POST"
              class="space-y-6"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-white-300"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class=""
                  >
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex justify-center w-full px-4 py-2 text-sm font-medium text-black border border-transparent rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Email me my password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="@/assets/img/backgrounds/ragnaros.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>

export default {
}
</script>
